<template>
  <div>
    <app-page-detail page-title="Berita Acara - Detail" page-toolbar-title="Berita Acara - Detail">
      <template #toolbar>
        <app-button @click="getDetail(true)" title="Refresh" mdi-icon="mdi-refresh" :loading="loading"></app-button>
        <app-button @click="printDocument()" title="Print" mdi-icon="mdi-printer"></app-button>

        <app-log-data-dialog module_name="beritaacara" :id="berita_acara_header.id">
          <app-button title="Document Log" mdi-icon="mdi-clipboard-text-clock"></app-button>
        </app-log-data-dialog>

        <v-divider vertical class="mx-2"></v-divider>

        <app-button :disabled="!showEditBtn" @click="redirect('Accounting.BeritaAcara.Edit', { id: id })" title="Edit" mdi-icon="mdi-pencil"></app-button>
        <app-button :disabled="!showDeleteBtn" @click="deleteData()" :loading="loading_delete" title="Delete Document" mdi-icon="mdi-delete"></app-button>
        <v-divider vertical class="mx-2"></v-divider>
        <app-button :disabled="!showSendApprovalBtn" @click="sendApproval()" :loading="loading_send_approval" title="Send Approval" mdi-icon="mdi-file-send"></app-button>
        <app-button :disabled="!showApproveBtn" @click="approveData()" :loading="loading_approve" title="Approve Document" mdi-icon="mdi-file-check"></app-button>
        <v-divider vertical class="mx-2"></v-divider>
        <app-button :disabled="!showCancelBtn" @click="cancelData()" :loading="loading_cancel" title="Cancel Document" mdi-icon="mdi-file-document-remove"></app-button>
        <app-button :disabled="!showReopenBtn" @click="reopenData()" :loading="loading_reopen" title="Re-Open Document" mdi-icon="mdi-file-restore"></app-button>
        <app-button :disabled="!showCloseBtn" @click="closeData()" :loading="loading_close_document" title="Close Document" mdi-icon="mdi-book-check"></app-button>
      </template>

      <v-row>
        <v-col cols="12" md="9">
          <card-expansion title="General" class="mb-3" :loading="loading">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field label="Document No." v-model="berita_acara_header.document_no_" hide-details outlined dense class="mb-2" readonly></v-text-field>
                <v-text-field label="Document Date" v-model="berita_acara_header.document_date" hide-details outlined dense class="mb-2" readonly></v-text-field>
                <v-text-field label="Area Code" v-model="berita_acara_header.area_code" hide-details outlined dense class="mb-2" readonly></v-text-field>
                <v-textarea label="Description" v-model="berita_acara_header.description" hide-details outlined dense class="mb-2" readonly rows="4"></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Closing Date" v-model="berita_acara_header.closing_date" hide-details outlined dense class="mb-2" readonly></v-text-field>
                <v-text-field label="Target Date" v-model="berita_acara_header.target_date" hide-details outlined dense class="mb-2" readonly></v-text-field>
                <v-text-field label="Total Price" prefix="Rp. " v-model="totalPriceFormatted" hide-details outlined dense class="mb-2 font-weight-bold" readonly></v-text-field>
              </v-col>
            </v-row>
          </card-expansion>
          <card-expansion title="Items" class="mb-3" :loading="loading">
            <v-row>
              <v-col cols="12">

                <div class="px-4">
                  <v-checkbox @change="selectedApprovalItemToogle = !selectedApprovalItemToogle" label="Select/Unselect All" v-show="isShowSelectedApprovalItem"></v-checkbox>
                </div>
                <v-sheet elevation="1">
                  <v-data-table :items="berita_acara_item" :headers="berita_acara_item_columns">
                    <template v-slot:[`item.item_approved`]="{ item }">
                      <div v-if="isShowSelectedApprovalItem">
                        <div v-if="item.original_item_approved === null">
                          <v-simple-checkbox v-model="item.item_approved" :ripple="false" color="primary"></v-simple-checkbox>
                        </div>
                        <div v-if="item.original_item_approved === false">
                          <v-simple-checkbox v-model="item.item_approved" :ripple="false" disabled="disabled" color="warning" title="Di tolak pada Approval sebelumnya." indeterminate></v-simple-checkbox>
                        </div>
                        <div v-if="item.original_item_approved === true">
                          <v-simple-checkbox v-model="item.item_approved" :ripple="false" color="primary"></v-simple-checkbox>
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="item.item_approved === null">
                          <v-icon>mdi-minus</v-icon>
                        </div>
                        <div v-if="item.item_approved !== null">
                          <v-icon v-if="item.item_approved" color="primary">mdi-check</v-icon>
                          <v-icon v-if="!item.item_approved" color="warning">mdi-cancel</v-icon>
                        </div>
                      </div>
                    </template>
                    <template v-slot:[`item.item_image`]="{ item }">
                      <image-viewer :src="resourceUrl(item.item_image)">
                        <v-img :src="resourceUrl(item.item_image)" max-width="60" max-height="60"></v-img>
                      </image-viewer>
                    </template>
                    <template v-slot:[`item.quantity`]="{ item }">
                      <span>{{ numberFormat(item.quantity, 2) }}</span>
                    </template>
                    <template v-slot:[`item.weight`]="{ item }">
                      <span>{{ numberFormat(item.weight, 2) }}</span>
                    </template>
                    <template v-slot:[`item.unit_price`]="{ item }">
                      <span>{{ numberFormat(item.unit_price, 2) }}</span>
                    </template>
                    <template v-slot:[`item.subtotal`]="{ item }">
                      <span>{{ numberFormat(item.quantity * item.unit_price, 2) }}</span>
                    </template>
                  </v-data-table>
                </v-sheet>
              </v-col>
            </v-row>
          </card-expansion>
          <card-expansion title="Attachments" class="mb-3" :loading="loading">
            <v-row>
              <v-col cols="12">
                <v-sheet elevation="1">
                  <v-data-table :items="berita_acara_attachment" :headers="berita_acara_attachment_columns">
                    <template v-slot:[`item.title`]="{ item }">
                      <span>
                        <a :href="resourceUrl(item.file_src)" target="_blank">{{ item.title }}</a>
                      </span><br>
                      <span>{{ item.description }}</span>
                    </template>
                  </v-data-table>
                </v-sheet>
              </v-col>
            </v-row>
          </card-expansion>
          <card-expansion title="Approval" class="mb-3" :loading="loading">
            <v-row>
              <v-col cols="12">
                <v-sheet elevation="1">
                  <v-data-table :items="berita_acara_approval" :headers="berita_acara_approval_columns">
                    <template v-slot:[`item.approved`]="{ item }">
                      <span v-if="item.approved == '1'">
                        <v-icon color="primary">mdi-check</v-icon>
                      </span>
                    </template>
                  </v-data-table>
                </v-sheet>
              </v-col>
            </v-row>
          </card-expansion>
        </v-col>
        <v-col cols="12" md="3">
          <document-info :value="berita_acara_header"></document-info>
          <app-comment :document_id="parseInt(berita_acara_header.id)" :source_document="berita_acara_header.source_document" :document_no_="berita_acara_header.document_no_"></app-comment>
        </v-col>
      </v-row>

    </app-page-detail>
  </div>
</template>

<script>
import DocumentInfo from './components/DocumentInfo.vue';
export default {
  components: { DocumentInfo },
  data() {
    return {
      id: null,
      loading: false,
      loading_send_approval: false,
      loading_reopen: false,
      loading_approve: false,
      loading_cancel: false,
      loading_delete: false,
      loading_close_document: false,
      dialog: false,
      modal1: false,
      berita_acara_header: {},
      user: {},
      berita_acara_item_columns: [
        {
          text: "Approved",
          value: "item_approved",
          sortable: false,
          align: "center",
        },
        { text: "Image", value: "item_image", sortable: false, align: "center" },
        {
          text: "Description", value: "description", sortable: false,
          cellClass: "text-no-wrap",
          class: "text-no-wrap",
        },
        { text: "Item No.", value: "item_no_", sortable: false },
        {
          text: "Item Description",
          value: "item_description",
          sortable: false,
          cellClass: "text-no-wrap",
          class: "text-no-wrap",
        },
        { text: "UoM", value: "unit_of_measure", sortable: false, align: "center" },
        { text: "Quantity", value: "quantity", sortable: false, align: "right" },
        { text: "Weight", value: "weight", sortable: false, align: "right" },
        { text: "Unit Price", value: "unit_price", sortable: false, align: "right" },
        {
          text: "Sub Total",
          value: "subtotal",
          sortable: false,
          align: "right",
        },
      ],
      berita_acara_item: [],
      berita_acara_attachment_columns: [
        { text: "Attachment", value: "title", sortable: false },
      ],
      berita_acara_attachment: [],
      berita_acara_approval_columns: [
        { text: "Approval", value: "approval_label", sortable: false },
        { text: "User", value: "username", sortable: false },
        { text: "Approve Status", value: "approved", sortable: false, align: 'center' },
        { text: "Approve Date", value: "approved_date", sortable: false },
      ],
      berita_acara_approval: [],

      selectedApprovalItemToogle: false,
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    selectedApprovalItemToogle(n, o) {
      this.berita_acara_item.forEach((item) => {
        if ((item.original_item_approved === null) || (item.original_item_approved === true)) {
          item.item_approved = n;
        }
      })
    },
  },
  computed: {
    totalPrice() {
      var total = 0;
      this.berita_acara_item.forEach((item) => {
        total = total + item.quantity * item.unit_price;
      });
      return total;
    },
    totalPriceFormatted() {
      var total = 0;
      this.berita_acara_item.forEach((item) => {
        total = total + item.quantity * item.unit_price;
      });
      return this.numberFormat(total);
    },
    showDeleteBtn() {
      if (this.inArray(this.berita_acara_header.status, ['closed', 'cancel'])) return false;

      if (this.berita_acara_header.created_by_username !== this.$store.state.loginInfo.username) return false;

      if ((this.modulePermission('beritaacara', 'delete'))
        && (!this.inArray(this.berita_acara_header.status, ['approved', 'closed']))
        && (this.inArray(this.berita_acara_header.status, ['open']))) {
        return true;
      }
      return false;
    },
    showReopenBtn() {
      if (this.inArray(this.berita_acara_header.status, ['closed', 'cancel'])) return false;

      if (this.berita_acara_header.created_by_username !== this.$store.state.loginInfo.username) return false;

      if ((this.modulePermission('beritaacara', 'reopen'))
        && (!this.inArray(this.berita_acara_header.status, ['approved']))
        && (!this.inArray(this.berita_acara_header.status, ['open']))) {
        return true;
      }
      return false;
    },
    showCancelBtn() {
      if (this.inArray(this.berita_acara_header.status, ['closed', 'cancel'])) return false;

      if ((this.modulePermission('beritaacara', 'cancel'))
        && (!this.inArray(this.berita_acara_header.status, ['approved']))
        && (!this.inArray(this.berita_acara_header.status, ['cancel']))) {
        if (this.berita_acara_header.created_by_username === this.$store.state.loginInfo.username) {
          return true;
        }
      }
      return false;
    },
    showSendApprovalBtn() {
      if (this.inArray(this.berita_acara_header.status, ['closed', 'cancel'])) return false;

      if ((this.modulePermission('beritaacara', 'send-approval')) && (this.berita_acara_header.status == 'open')) {
        return true;
      }
      return false;
    },
    showApproveBtn() {
      if (this.inArray(this.berita_acara_header.status, ["closed", "cancel"]))
        return false;

      if (
        this.modulePermission("beritaacara", "approve") &&
        this.berita_acara_header.status == "waiting-approval"
      ) {
        let show = false;
        let l_uname = this.$store.state.loginInfo.username;
        for (var i = 0; i < this.berita_acara_approval.length; i++) {
          let apprv = this.berita_acara_approval[i].approved;
          let uname = this.berita_acara_approval[i].username;

          if (apprv == 0) {
            if (uname == l_uname) {
              show = true;
              return true;
            }
          }
        }

        return show;
      }
      return false;
    },
    showEditBtn() {
      if (this.inArray(this.berita_acara_header.status, ['closed', 'cancel'])) return false;

      if ((this.modulePermission('beritaacara', 'modify'))
        && (this.berita_acara_header.status == 'open')) {
        return true;
      }
      return false;
    },
    showCloseBtn() {
      if (this.inArray(this.berita_acara_header.status, ['closed', 'cancel'])) return false;

      if ((this.modulePermission('beritaacara', 'close')) && (this.berita_acara_header.status == 'approved')) {
        return true;
      }
      return false;
    },
    isShowSelectedApprovalItem() {
      if (this.modulePermission("beritaacara", "approve")) {

        if (this.inArray(this.berita_acara_header.status, ["waiting-approval"]))
          return true;

      }

      return false;
    },
  },
  methods: {
    refreshDetail() {
      this.getDetail(true)
    },
    showDialog() {
      this.getDetail(true);
    },
    async getDetail(refresh = false) {

      this.loading = true

      this.berita_acara_header = {}

      if (refresh == true)
        this.AxiosStorageRemove("GET", "beritaacara/detail");

      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.id,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("beritaacara/detail", config)
        .then((res) => {
          this.berita_acara_header = res.data.data.berita_acara_header;
          res.data.data.berita_acara_item.forEach((item) => {
            item.original_item_approved = item.item_approved;

            this.berita_acara_item.push(item);
          })
          this.berita_acara_attachment = res.data.data.berita_acara_attachment;
          this.berita_acara_approval = res.data.data.berita_acara_approval;
          this.user = res.data.data.user;

          this.showLoadingOverlay(false);

          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
    async reopenData() {
      if (!confirm("Re-open document?")) return;
      this.loading_reopen = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("id", this.berita_acara_header.id);
      await this.$axios.post("beritaacara/reopen", formData)
        .then((res) => {
          this.loading_reopen = false;
          this.showLoadingOverlay(false);
          this.showAlert(res.data.status, res.data.message);

          this.refreshDetail();
        })
        .catch((error) => {
          this.loading_reopen = false
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
    async approveData() {
      if (!confirm("Approve document?")) return;
      this.loading_approve = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("id", this.berita_acara_header.id);

      let i = 0;
      this.berita_acara_item.forEach((item) => {

        formData.append("item_approved[" + i + "][id]", item.id);
        formData.append("item_approved[" + i + "][berita_acara_header_id]", item.berita_acara_header_id);
        formData.append("item_approved[" + i + "][item_no_]", item.item_no_);
        formData.append("item_approved[" + i + "][item_approved]", item.item_approved);

        i++;
      })
      await this.$axios.post("beritaacara/approve", formData)
        .then((res) => {
          this.loading_approve = false;
          this.showLoadingOverlay(false);
          this.showAlert(res.data.status, res.data.message);

          this.refreshDetail();
        })
        .catch((error) => {
          this.loading_approve = false
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
    async cancelData() {
      if (!confirm("Cancel document?")) return;
      this.loading_cancel = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("id", this.berita_acara_header.id);
      await this.$axios.post("beritaacara/cancel", formData)
        .then((res) => {
          this.loading_cancel = false;
          this.showLoadingOverlay(false);
          this.showAlert(res.data.status, res.data.message);

          this.refreshDetail();
        })
        .catch((error) => {
          this.loading_cancel = false
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
    async deleteData() {
      if (!confirm("Delete document?")) return;
      this.loading_cancel = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("id", this.berita_acara_header.id);
      await this.$axios.post("beritaacara/delete", formData)
        .then((res) => {
          this.loading_cancel = false;
          this.showLoadingOverlay(false);
          this.showAlert(res.data.status, res.data.message);

          this.refreshDetail();
        })
        .catch((error) => {
          this.loading_cancel = false
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
    async sendApproval() {
      if (!confirm("Send Approval document?")) return;
      this.loading_reopen = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("id", this.berita_acara_header.id);
      await this.$axios.post("beritaacara/send-approval", formData)
        .then((res) => {
          this.loading_reopen = false;
          this.showLoadingOverlay(false);
          this.showAlert(res.data.status, res.data.message);

          this.refreshDetail();
        })
        .catch((error) => {
          this.loading_reopen = false
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
    async closeData() {
      const post = async () => {
        this.loading_close_document = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.berita_acara_header.id);
        await this.$axios
          .post("beritaacara/close", formData)
          .then((res) => {
            this.loading_close_document = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_close_document = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };
      this.showConfirm("Confirm", "Close document?", post);
    },

    printDocument() {
      this.redirect('Accounting.BeritaAcara.PrintDocument', { id: this.id });
    },
  },

  beforeMount() {

    if (typeof this.$route.query.id !== 'undefined') {
      this.id = parseInt(this.$route.query.id);
    }
    else {
      this.id = parseInt(this.$route.params.id);
    }
  },

  mounted() {

    this.getDetail(true);
  }
};
</script>
